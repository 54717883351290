<template>
  <div class="container" >    
    <div v-if="JSON.stringify(obj)!=='{}'">
        <b-card
          header="共管人設定"
          header-border-variant="white"
          header-bg-variant="light"
          footer-bg-variant="light"
          body-border-variant="white"        
          align="center"
          class="card-3"
        >
        <b-card-body  >
          <div id="style-3" style="height:580px;padding:5px 0px 5px 0px;overflow-x:hidden;overflow-y:visible;" >
                <div class="text-center">
                    <a-row type="flex" >
                        <a-col :span="4" :offset="1" v-for="data in items.data" :key="data.id">
                                <a-popover title="權限表">
                                  <template slot="content">
                                    <div id="style-3" style="height:200px;width:200px;padding:0px 0px 0px 0px;overflow-x:hidden;overflow-y:visible;">
                                    <ol>
                                      <li v-for="item in data.permissions" :key="item.id">
                                      {{item.title}}
                                        <ul v-for="obj in item.acts_detailpermission" :key="obj.id">
                                          <li>{{obj.title}}</li>
                                        </ul>
                                      </li>
                                    </ol>
                                    </div>
                                  </template>
                                  <div style="padding:5px;">                                                                                 
                                      <b-avatar :src="data.pri_url" text="data.username" size="7rem" class="align-baseline"></b-avatar>
                                      <br/> 
                                      <b-icon-person-square v-if="data.master=='Y'"></b-icon-person-square>                                    
                                      <b-icon-person v-if="data.master=='N'"></b-icon-person>
                                      &ensp;
                                      <span style="font-size:14px;cursor:pointer;" v-if="data.master=='Y'" @click="openPermission(data)" v-text="'(管理者)'"></span>
                                      <span style="font-size:14px;" v-else v-text="'(共管人)'"></span> 
                                      <br/> 
                                      <button type="button" @click="editors(data)" class="btn btn-light btn-sm">{{data.displayname}}</button>                                                                    
                                  </div>   
                                </a-popover>                          
                        </a-col>                  
                    </a-row>  
                </div>                 
            
          </div>          
        </b-card-body>
          <template #footer>
              <small class="text-muted">
              <a-button-group >
                <a-button type="primary" @click="addman()">
                  <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="person-plus" variant="light" />
                  新增共管人
                </a-button>
              </a-button-group>                
              </small>
          </template>        
        </b-card>


    </div>
        <b-modal ref="my-loading" id="modal-2" title="Loading" @hide="onHide" centered hide-footer hide-header>
          <div class="text-center">
              <b-spinner  variant="primary" label="Text Centered"></b-spinner>
              <strong >Loading....</strong>
          </div>
        </b-modal> 
        <b-modal ref="my-loadingx" id="modal-x" size="lg" title="Loading" @hide="onHide" centered hide-footer hide-header>
          <div class="text-center">
                <div class="text-center">  
                    <p class="h6"><b-icon-vinyl-fill></b-icon-vinyl-fill>人員清單</p> 
                    <a-steps :current="current" :size="sizes">
                      <a-step v-for="item in steps" :key="item.title" :title="item.title" />
                    </a-steps>                                           
                </div>  
                <div v-if="current==0" class="text-left">
                  <a-input-search placeholder="請輸入查詢名稱" style="margin: 10px;width: 200px" enter-button @search="onSearch" /> 
                </div>      
                 
                <div class="overflow-auto" style="height: 450px;width:100%;" id="style-3">                    
                    <div class="steps-content">                      
                        <a-table v-if="current==0"
                            :columns="mcolumns"
                            :row-key="record => record.id"
                            :data-source="udata"
                            :pagination="upagination"
                            :loading="uloading"
                            :row-selection="{ type:'radio',selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                            @change="uhandleTableChange"
                            :row-class-name="checkrow"
                            bordered
                        >                                                
                            <template slot="pri_url" slot-scope="pri_url"> 
                                <b-avatar :src="pri_url" variant="primary" text="" size="2.5rem" class="align-baseline"></b-avatar>                                 
                            </template> 
                            <template slot="username" slot-scope="record"  >
                                <div>
                                    <span v-if="record.username!=''" style="color:#F08C3C;" v-text="maskName(record.username)"></span>                                   
                                </div>
                            </template> 
                            <template slot="display_name" slot-scope="record"  >
                                <div>
                                    <span v-if="record.display_name!=''" style="color:#2c7190;" v-text="record.display_name"></span>                                    
                                </div>
                            </template>                                                                                
                        </a-table>
                        <div v-if="current==1">
                            <p></p>
                            <div class="form-group checkboxcss" v-for="item in items.data[0].permissions" :key="item.id">                                
                                <label for="idexample">{{ item.title }} <b-icon icon="file-earmark-check-fill"></b-icon></label>
                                <br />
                                    <a-row type="flex" style="text-align:left;" >
                                        <a-col :span="8" :offset="-1" v-for="obj in item.acts_detailpermission" :key="obj.id">
                                                <div >       
                                                    <span>                                                         
                                                    <input v-model="selected"  type="checkbox" :value="item.id+':'+obj.id"/> 
                                                    : {{ obj.title }} 
                                                    </span>
                                                </div> 
                                        </a-col>                  
                                    </a-row>                                
                            </div>                              
                        </div>
                        <div v-if="current==2">
                          <p></p>                          
                          <b-alert show variant="success">您的共管人參數設定完畢, 請按下存檔後資料將送出</b-alert>
                        </div>

                    </div>
                </div>
              <a-button-group >
                <a-button type="dashed" @click="cancel()" :loading="loading">
                  <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="x-circle" variant="secondary" />
                   取消 
                </a-button>
                <a-button v-if="current > 0" type="primary" :loading="loading"  @click="prev">
                  <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="chevron-left" variant="secondary" />
                  上一步
                </a-button>                                
                <a-button v-if="current < steps.length - 1"  :disabled="! steps[current].state"  type="primary" @click="next">
                  <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="chevron-right" variant="secondary" />
                  下一步
                </a-button>
                <a-button
                  v-if="current == steps.length - 1"
                  type="primary"
                  @click="save()"
                  :loading="loading"
                >
                <a-icon type="save" />
                  存 檔 
                </a-button>              
              </a-button-group>  

          </div>
        </b-modal>   

        <b-modal ref="my-loadingm" id="modal-m" title="Loading" @hide="onHide" centered hide-footer hide-header>
          <div class="text-center">       
                <div class="overflow-auto" style="height: 450px;width:100%;" id="style-3">
                  <div class="form-group checkboxcss" v-for="item in uitem.permissions" :key="item.vals">                                
                      <label for="idexample">{{ item.title }} <b-icon icon="file-earmark-check-fill"></b-icon></label>
                      <br />
                          <a-row type="flex" style="text-align:left;" >
                              <a-col :span="8" :offset="-1" v-for="obj in item.acts_detailpermission" :key="obj.vals">
                                      <div >       
                                          <span>                                                       
                                          <input v-model="selectedm" type="checkbox"  :value="item.vals+':'+obj.vals"/> 
                                          : {{ obj.title }} 
                                          </span>
                                      </div> 
                              </a-col>                  
                          </a-row>                                
                  </div> 

                </div>
              <a-button-group >
                <a-button type="dashed" @click="cancel2()" :loading="loading2">
                  <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="x-circle" variant="secondary" />
                   取消 
                </a-button>
                <a-button
                  type="dashed"
                  @click="remove()"
                  :loading="loading2"
                >
                <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="trash" variant="secondary" />
                  刪 除
                </a-button>  
                <a-button
                  type="dashed"
                  @click="save2()"
                  :loading="loading2"
                >                
                <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="arrow-repeat" variant="secondary" />
                  更 新 
                </a-button>                                              
              </a-button-group>  

          </div>
        </b-modal> 

        <b-modal ref="my-admin" id="modal-admin" title="Loading"  @hide="onHide" centered hide-footer hide-header>
          <div class="text-center">       
            <div class="overflow-auto" style="height: 450px; width: 100%;" id="style-3">
              <a-radio-group v-model="selectedValue" class="radio-group">
                <div v-for="data in items.data" :key="data.id" :class="data.id == selectedValue? 'cssSelected item-container':'cssUnSelected item-container'">
                  <b-avatar 
                    :src="data.pri_url" 
                    variant="primary" 
                    text="data.username" 
                    size="7rem" 
                    class="align-baseline avatar">
                  </b-avatar>
                  <div class="text-container">
                    <span class="displayname" v-text="data.displayname"></span><br/>
                    <a-radio-button :value="data.id" :checked="data.master === 'Y'" class="radio-button">
                      選擇 <b-icon icon="pencil-square" scale="1" variant="primary"></b-icon>
                    </a-radio-button>
                  </div>
                </div>
              </a-radio-group>
            </div>
              <div style="padding:0.3rem;" ></div>
              <a-button-group >
                <a-button type="dashed" @click="cancel3()" :loading="loading2">
                  <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="x-circle" variant="secondary" />
                   取消 
                </a-button>                 
                <a-button
                  type="dashed"
                  @click="updateAdmin()"
                  :loading="loading2"
                >                
                <b-icon  style="margin-right: 0.3rem;" scale="1.1" icon="arrow-repeat" variant="secondary" />
                  更 新 
                </a-button>                                              
              </a-button-group>  
          </div>
        </b-modal>         


        <b-alert
          v-model="showTop"
          class="position-fixed fixed-top m-0 rounded-0"
          style="z-index: 2000;"
          variant="success"
          dismissible
        >
          更新成功
        </b-alert>              

  </div>        
</template>
<script>
import { mapState,mapActions } from "vuex";
import { makorgService,cmessService } from '../../_services'

const queryuser = params => {
  return cmessService.findusListName(params.page,params.name)
}

const querymenber = params => {
  return makorgService.querymenber(params.id) 
};

const queryfucntion = params => {
  return makorgService.queryfucntion(params.id)
};


const postorgman = obj => {
  return makorgService.postorgman(obj)
}; 

const deleteorgman = obj => {
  return makorgService.deleteorgman(obj)
};


const updateorgman = obj => {
  return makorgService.updateorgman(obj)
};


const putOrgAdmin = obj => {
  return makorgService.putOrgAdmins(obj)
};

const mcolumns = [
  {
    title: '圖像',
    dataIndex: 'pri_url',
    width: '20%',    
    scopedSlots: { customRender: 'pri_url' },    
  },       
  {
    title: '註冊帳號',
    dataIndex: '',
    scopedSlots: { customRender: 'username' },      
    width: '30%',
  },   
  {
    title: 'Line顯示名稱',
    dataIndex: '',
    scopedSlots: { customRender: 'display_name' },      
    width: '30%',
  },  

];

export default {
  name: 'Partner',   

  data() {
    return {
      funarry:[],
      orgdata:[], 
      selected: [],   
      selectedm:[],
      uitem:[], 
      usdata:{}, 
      obj: {},
      showTop:false,
      loading: false,   
      loading2: false,       
      items:[],  
      mcolumns,  
      name:"all",
      udata: [],
      cmaster:null, 
      upagination: {defaultPageSize:4},  
      selectedRowKeys: [],  
      uloading: false,  
      sizes:'small',
      selectedValue: null,
      selectedItems: null,      
      form: {
           id:'',
           uid:'',
           desc : '',            
           name : '',
           title : '' ,
           company : '',
           finkurl : '',
           iinkurl : '',
           url:'',
          }, 
        current: 0,
              steps: [
                {
                  title: '第一步 (選擇共管人)',
                  content: 'First-content',
                  state:false,
                },
                {
                  title: '第二步 (設定權限)',
                  content: 'Second-content',
                  state:false,
                },
                {
                  title: '存檔',
                  content: 'Last-content',
                },
              ],                                
            };
  },
  mounted(){
    this.obj=this.datas.find(item => item.id == this.defaultval.id)
    this.inid()
  },
  computed: {
    ...mapState("account", ["status", "user"]), 
    ...mapState("makeorg", ["datas","defaultval"]),
  }, 
  watch: {
      defaultval(val){
          this.obj=this.datas.find(item => item.id == val.id)
          this.inid()
      },
      selected(){
        if(this.selected.length>0){
            this.steps[1].state = true
        }
        if(this.selected.length==0){
          this.steps[1].state = false
        }
      }
  },
  methods: {
    ...mapActions('makeorg',['readorgdata','insertorg']),      
    async inid(){
      await this.fetch({id:this.defaultval.id})
      await this.fetch2({id:'Y'})
      await this.usfetch({results:4,page:1,pageSize: 4,name: this.name});
    },
    addman(){
        this.checkman()
        this.$refs['my-loadingx'].show()
    },
    maskName(name) {
            const len = name.length;
            const half = Math.ceil(len / 2);
            const maskedPart = '*'.repeat(half);
            const visiblePart = name.slice(half);
            return maskedPart + visiblePart;
    },    
    checkman(){
        if(this.items.data!==undefined && this.items.data!==null && this.udata !==null){
          this.udata = this.udata.map(x=> {                    
            if(this.items.data.find(item => item.uid==x.id)){
              x.expanded=true; 
            }else{
              x.expanded=false; 
            }                                      
            return x;
            }); 
        }

    },
    editors(val){
      let tmp =[]
      this.selectedm=[]   
      this.usdata ={}   
      if(val.master==='Y'){
        return false
      }
      this.uitem = this.items.data[0] //取得最高管理員的權限
      //checked 的  v-model="selectedm" 跟 :value 做綁定
      for(var i in val.permissions){
        for(var j in val.permissions[i].acts_detailpermission){
          tmp.push({id:val.permissions[i].acts_detailpermission[j].id})
          this.selectedm.push(val.permissions[i].vals+':'+val.permissions[i].acts_detailpermission[j].vals)
        }
      }
      this.usdata=val
      this.$refs['my-loadingm'].show()
    },
    openPermission(item){  
      const masterItem = this.items.data.find(data => data.master === 'Y');
      if (masterItem) {
        this.selectedValue = masterItem.id;
      }    
      this.selectedItems = item
      this.$refs['my-admin'].show()
    },

    checkrow(record){
      return record.expanded? "disabled-row":""     
    },
    fetch(params = {}) {
      querymenber({
        ...params,
      }).then(({ data }) => {
          this.items = data   
          for(var obj in this.items.data){
            if(this.items.data[obj].master=='Y'){ 
              this.cmaster=this.items.data[obj].id
            }
          }               
      });
    }, 
    
    fetch2(params ={}){
      queryfucntion({
        ...params,
      }).then(({data}) =>{
        this.funarry = data       
      })
    },

    uhandleTableChange(pagination, filters, sorter) {

        const pager = { ...this.upagination };
        pager.current = pagination.current;
        this.upagination = pager;
        //console.log(this.pagination);
        this.usfetch({
            results: pagination.pageSize,
            page: pagination.current,
            name: this.name,
            sortField: sorter.field,
            sortOrder: sorter.order,
            ...filters,
      });
    }, 
    
        usfetch(params = {}) { 
        this.uloading = true;
        queryuser({
            //results: 4,
            ...params,
        }).then(({ data }) => {
            const pagination = { ...this.upagination };
            pagination.total = data.info.totail;
            this.uloading = false;
            this.udata = data.results;
            this.upagination = pagination;           
            this.checkman()            

        });
        },     

    save(){
        this.loading = true         
        let group=[]
        let list=[]
        let resultes =[]
        for(var j in this.selected){
            let strs =this.selected[j].split(':')
            group.push(strs[0])
        }
        for(var i in this.selected){
            let str = this.selected[i].split(':')            
            list.push({id:str[0],fid:str[1]})
        }

        group = group.filter((c, index) => {
            return group.indexOf(c) === index;
        }); 
        for(var s in group){
            let rs = this.items.data[0].permissions.find(item=>item.id==group[s])
            let ddata = []
            for(var m in list){
              if(list[m].id==group[s]){
                ddata.push(list[m])
              }
            }
            let detail =[]
            for(var k in ddata){                
               let xs = this.items.data[0].permissions.find(item=>item.id==group[s]).acts_detailpermission.find(odata => odata.id==ddata[k].fid)               
               let dtails={
                 id:xs.id,
                 seq:xs.seq,
                 title:xs.title,
                 vals:xs.vals
               }
               detail.push(dtails)
            }
            let obj={
              id:rs.id,
              seq:rs.seq,
              title:rs.title,
              vals:rs.vals,
              icon:rs.icon,
              states:rs.states,
              acts_detailpermission:detail
            } 
            resultes.push(obj)         
        }

        let tmp={
          orgid: this.defaultval.id,          
          useid:this.form.uid,
          master:'N',
          modes: 'Y',
          permissions:resultes
        }         
        postorgman(tmp).then(({data}) =>{
                if(data.code==200){
                    this.showTop=true;
                    setTimeout(() => {   
                      this.current =0 
                      this.loading = false                        
                      this.showTop = false
                      this.selectedRowKeys=[]
                      this.selected=[]
                      this.steps[0].state = false
                      this.steps[1].state = false
                      this.inid()
                      this.$refs['my-loadingx'].hide()
                      clearInterval();
                    }, 2000);                            
                }
            }); 
       

    },

    
    save2(){
        this.loading2 = true         
        let group=[]
        let list=[]
        let resultes =[]
        for(var j in this.selectedm){
            let strs =this.selectedm[j].split(':')
            group.push(strs[0])
        }
        for(var i in this.selectedm){
            let str = this.selectedm[i].split(':')            
            list.push({id:str[0],fid:str[1]})
        }

        group = group.filter((c, index) => {
            return group.indexOf(c) === index;
        }); 
        for(var s in group){
            let rs = this.items.data[0].permissions.find(item=>item.vals==group[s])
            let ddata = []
            for(var m in list){
              if(list[m].id==group[s]){
                ddata.push(list[m])
              }
            }
            let detail =[]
            for(var k in ddata){                
               let xs = this.items.data[0].permissions.find(item=>item.vals==group[s]).acts_detailpermission.find(odata => odata.vals==ddata[k].fid)               
               let dtails={
                 id:xs.id,
                 seq:xs.seq,
                 title:xs.title,
                 vals:xs.vals
               }
               detail.push(dtails)
            }
            let obj={
              id:rs.id,
              seq:rs.seq,
              title:rs.title,
              vals:rs.vals,
              icon:rs.icon,
              states:rs.states,
              acts_detailpermission:detail
            } 
            resultes.push(obj)         
        }

        let tmp={
          orgid: this.defaultval.id,          
          useid:this.usdata.uid,
          master:'N',
          modes: 'Y',
          permissions:resultes
        }         
        updateorgman(tmp).then(({data}) =>{
                if(data.code==200){
                    this.showTop=true;
                    setTimeout(() => {   
                      this.loading2 = false                        
                      this.showTop = false
                      this.inid()
                      this.$refs['my-loadingm'].hide()
                      clearInterval();
                    }, 2000);                            
                }
            });       
    },    

    remove(){        
        this.loading2 = true;        
        deleteorgman(this.usdata).then(({data}) =>{ 
                if(data.code==200){
                    this.showTop=true;
                    setTimeout(() => {   
                      this.loading2 = false                        
                      this.showTop = false
                      this.inid()
                      this.$refs['my-loadingm'].hide()
                      clearInterval();
                    }, 2000);                            
                }
            });        

    },

    cancel(){
      this.$refs['my-loadingx'].hide()
    },

    cancel2(){
      this.$refs['my-loadingm'].hide()
    }, 
    cancel3(){
      this.$refs['my-admin'].hide()
    },       

    next(){
          this.current++;
    },
    prev() {
          this.current--;
    },  
    
    async updateAdmin(){
      if(this.selectedValue!==null){
       await putOrgAdmin({oid:this.selectedItems.fid,id:this.selectedValue}).then(({data}) =>{
                if(data.code==200){
                    // 更新當前 master 為 'N'
                    const currentMasterIndex = this.items.data.findIndex(data => data.master === 'Y');
                    if (currentMasterIndex !== -1) {
                      this.$set(this.items.data, currentMasterIndex, {
                        ...this.items.data[currentMasterIndex],
                        master: 'N'
                      });
                    }

                    // 更新選中的項目為 'Y'
                    const newMasterIndex = this.items.data.findIndex(data => data.id === this.selectedValue);
                    if (newMasterIndex !== -1) {
                      this.$set(this.items.data, newMasterIndex, {
                        ...this.items.data[newMasterIndex],
                        master: 'Y'
                      });
                    }
                  this.$refs['my-admin'].hide()                           
                }
            });         

      }
    },
      onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
        let rs = this.udata.filter((el)=> { return el.id === selectedRowKeys[0]}); 
            this.form.name =rs[0].username==''?rs[0].display_name: rs[0].username   
            this.form.url = rs[0].pri_url  
            this.form.uid =  selectedRowKeys[0]
            this.form.id =rs[0].id 
            let newArray = this.items.data.filter(function (el) {
                  return el.uid ===selectedRowKeys[0];
              }
              );
            if(newArray.length===0){
              this.steps[0].state = true
            }else{
              this.steps[0].state = false
            }
        },  

    onReset(event) {
        event.preventDefault()
        this.$nextTick(() => {

        })
    },  
 
        onHide(evt) {
            if(evt.trigger === "backdrop"){
                evt.preventDefault();
                //this.handleBackdrop();
            }
        }, 
        catImg(imgs) {
          return require(`../../assets/${imgs}`);
        }, 
        
        async onSearch(value) {
          if(value==""){
            this.name = "all"
          }else{
            this.name = value
          }
          await this.usfetch({results:4,page:1,pageSize: 4,name: this.name});
        },
  },
};
</script>
<style>
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px #535151;;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #535151;
}
.divhidden{
  visibility:hidden;
}
.frams{
  cursor: pointer;
  width:685px;
  height:230px;
  background-color: #bdcbe2;
  border-style:dotted;
  border-width:2px;
  border-radius: 17px;
  border-color:#385e97;
}
.disabled-row {
  background-color: #f1de6e;
  pointer-events: none;
}
.checkboxcss{
  background-color:#f0f2f5;
  margin:6px;
  padding:5px;
  border-style:dotted;
  border-width:1px;
  border-color:#d0d2d5;
}

.overflow-auto {
  overflow-y: auto;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.item-container {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.avatar {
  margin-right: 10px;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.displayname {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.radio-button {
  display: flex;
  align-items: center;
}

.cssSelected {
  font-size:16px;
  font-weight:bold;
  padding-top:0.28rem;
  background: linear-gradient(-45deg, #ffffff, #73bcda, #bfe7f7);
}
.cssUnSelected {
  font-size:15px;
  padding-top:0.28rem;
  background: linear-gradient(-45deg, #ffffff, #d8edf5, #ffffff);
}

</style>


